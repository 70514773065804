import { createApp } from 'vue'
import App from './App.vue'
import i18n from "@/plugins/w/i18n/index"
import router from '@/router'
import store from '@/store'
import axios from 'axios'

import Vue from 'vue'
import 'hammerjs'

import { IonicVue, IonGrid, IonRow } from '@ionic/vue'

//base-layout tu nebude, pouzit vue-layout


/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css'
import '@ionic/vue/css/structure.css'
import '@ionic/vue/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css'
import '@ionic/vue/css/float-elements.css'
import '@ionic/vue/css/text-alignment.css'
import '@ionic/vue/css/text-transformation.css'
import '@ionic/vue/css/flex-utils.css'
import '@ionic/vue/css/display.css'

/* Theme variables */
// import './plugins/app/theme/variables.css'
import './plugins/app/_theme/app.scss'
import './plugins/app/_theme/_spacing.scss'
import './plugins/app/_theme/font.scss'
import './plugins/app/_theme/utilities.scss'
import './plugins/app/_theme/media-desktop.scss'


import './plugins/app/_theme/ionic/_globals.scss'
import './plugins/app/_theme/ionic/ion-backdrop.scss'
import './plugins/app/_theme/ionic/ion-button.scss'
import './plugins/app/_theme/ionic/ion-card.scss'
import './plugins/app/_theme/ionic/ion-modal.scss'


axios.defaults.baseURL = process.env.VUE_APP_API_URL

const app = createApp(App)
	.use(IonicVue)
	.use(router)
	.use(store)
	.use(i18n)
app.mount('#app')

import { registerIonicComponents } from '@/plugins/w/ionic/'

registerIonicComponents(app)
app.component('IonGrid', IonGrid)
app.component('IonRow', IonRow)

//Camera
import { defineCustomElements } from '@ionic/pwa-elements/loader'
defineCustomElements(window)

import { Plugins, StatusBarStyle } from '@capacitor/core';
const { StatusBar } = Plugins;

StatusBar.setStyle({
	style: StatusBarStyle.Light,
});
