import { createI18n } from 'vue-i18n/index'
import Translations from './translations'

Translations.setup('en', {
  sk: 'Slovenčina',
  en: 'English',
  cs: 'Čeština',
})

const i18n = createI18n({
  locale: Translations.getLocale(),
  fallbackLocale: Translations.getDefaultLocale(),
  messages: Translations.loadLocaleMessages(),
  missing: Translations.missingTranslation.bind(Translations),
  silentTranslationWarn: true,
  postTranslation: Translations.postTranslation.bind(Translations),
})

export default i18n
