import {Plugins} from '@capacitor/core'
const { Device, Storage } = Plugins

import i18n from './index'
import store from '@/store'

class Translations {

  constructor() {
    this.n = '🔴'
    this.y = '🟢'

    this.locale
    this.defaultLocale
    this.settings

    this._loadOrGetLocale()
  }

  setup(defaultLocale, settings){
    this.defaultLocale = defaultLocale
    this.settings = settings
  }

  setLocale(locale){
    this.locale = locale
    i18n.global.locale = locale
    store.commit('offline/languageUpdated', locale)
    Storage.set({key: 'locale', value: locale})
  }

  getLocale(){
    return this.locale
  }

  getLocales(){
    return this.settings
  }

  getDefaultLocale(){
    return this.defaultLocale
  }

  missingTranslation(locale, key){
    return key + this.n
  }

  postTranslation(string){
    string = string.split('^').join('<br>')
    return string

    if (string === '')
      return string + this.n

    return string.includes(this.n) ? string : string + this.y
  }

  loadLocaleMessages() {
    const locales = require.context('../../../locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
    const messages = {}

    locales.keys().forEach((key) => {
      const matched = key.match(/([A-Za-z0-9-_]+)\./i)
      if (matched && matched.length > 1) {
        const locale = matched[1]
        messages[locale] = locales(key)
      }
    })

    return messages
  }

  async _loadOrGetLocale() {
    let locale
    const storageLocale = await Storage.get({key: 'locale'})

    if (!storageLocale.value) {
      const deviceLangCode = (await Device.getLanguageCode()).value.split('-')[0]
      locale = Object.keys(this.getLocales()).includes(deviceLangCode) ? deviceLangCode : 'en'
    } else
      locale = storageLocale.value

    if (locale)
      this.locale = locale
    else
      this.locale = this.defaultLocale

    this.setLocale(this.locale)
  }
}

export default new Translations ()
