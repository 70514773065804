import { createRouter, createWebHashHistory } from '@ionic/vue-router'

const routes = [
	{
		path: '/',
		name: 'Campaigns',
		component: () => import ('@/plugins/app/campaigns/campaigns.vue')
	},
	{
		path: '/welcome',
		name: 'Landing',
		component: () => import ('@/plugins/app/landing/landing.vue'),
	},
	{
		path: '/categories',
		name: 'Categories',
		component: () => import ('@/plugins/app/categories/categories.vue')
	},
	{
		path: '/categories/:id',
		name: 'CategoriesId',
		component: () => import ('@/plugins/app/categories/categories.vue')
	},
	{
		path: '/categories/:categoryId/cards-ui',
		name: 'Cards-ui',
		component: () => import ('@/plugins/app/cards/cards-ui.vue')
	},
	{
		path: '/info',
		name: 'Info',
		component: () => import ('@/plugins/app/info/info.vue')
	},
	{
		path: '/posts/:post',
		name: 'Posts',
		component: () => import ('@/plugins/app/landing/landing.vue')
	}
]

const router = createRouter({
	history: createWebHashHistory(process.env.BASE_URL),
	routes
})

export default router
