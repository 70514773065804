import axios from 'axios'
import { getField, updateField } from 'vuex-map-fields'

import { Plugins } from '@capacitor/core'
const { Device } = Plugins

export default {
	namespaced: true,

	state: {
		campaigns: [],
		categories: [],
		cards: [],
		language: 'en',
		languagesAllowed: ['en'],
		frameEnabled: false,
		campaign: {},
		activeCampaign: null,
	},
	mutations: {
		campaignsLoaded(state, categories) {
			state.campaigns = categories
		},
		categoriesLoaded(state, categories) {
			state.categories = categories
		},
		languagesUpdated(state, languagesAllowed) {
			state.languagesAllowed = languagesAllowed
		},
		languageUpdated(state, languagesAllowed) {
			state.language = languagesAllowed
		},
		activeCampaignUpdated(state, activeCampaign) {
			state.activeCampaign = activeCampaign
		},
		campaignUpdated(state, campaign) {
			state.campaign = campaign
		},
		cardsLoaded(state, cards) {
			state.cards = cards
		},
		enableFrame(state){
			// state.frameEnabled = !state.frameEnabled
			state.frameEnabled = true
		},
		disableFrame(state){
			// state.frameEnabled = !state.frameEnabled
			state.frameEnabled = false
		},
		updateField
	},
	getters: {
		getField
	},
	actions: {
		async loadCampaigns( {commit}) {
			const data = await axios.get('/campaigns')
			const languagesAllowed = [...new Set(data.data.map(c => c.language))]

			commit('campaignsLoaded', data.data)
			commit('languagesUpdated', languagesAllowed.sort())
		},

		async loadCampaignCategories({ commit, state }) {
			if(!state.activeCampaign)
				return

			const categories = []
			let cardsData = []

			//Tries to load from server if it fails it should load from storage
			try {
				const data = await axios.get('/campaign/id/' + state.activeCampaign)
				cardsData = data.data.data.cards
				let addedCategoryNames = []
				cardsData.forEach(category => {
					if (!addedCategoryNames.includes(category.category.name)) {
						categories.push(category.category)
						addedCategoryNames.push(category.category.name)
					}
				})
				categories.sort((a,b) => {
					return a.id - b.id
				})

				commit('campaignUpdated', data.data.data)
				commit('categoriesLoaded', categories)
				commit('cardsLoaded', cardsData)

			} catch (error) {
				//Load from storage here
				//Maybe add an offline flag into state?
				console.error(error)
			}

		}
	}
}
